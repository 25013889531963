import { Box, CircularProgress, Divider, Paper, Stack, Typography } from '@mui/material';

function InterventionResultBlock({intervention, data}) { 

  return (
    <Paper color={'#000000'} elevation={3} style={{gap: '10px', flex: '1 1 calc(25% - 10px)', minHeight: '150px', minWidth: '200px', boxSizing: 'border-box', padding: '5px', }}>
        <Stack>

            <Typography textAlign="center" variant="body2" gutterBottom color={'text.secondary'}>
                Sterilizations:
            </Typography>
            <Typography textAlign="center" variant="h6" gutterBottom color={'#000000'}>
                {intervention.monthly_sters}
            
            </Typography>
            <Divider/>
            <Box p={1}>
                <Typography textAlign="center" variant="body2" gutterBottom color={'text.secondary'}>
                    Starting month: <strong>{intervention.intervention_start}</strong>
                </Typography>

                <Typography textAlign="center" variant="body2" gutterBottom color={'text.secondary'}>
                    Duration (months): <strong>{intervention.intervention_duration}</strong>
                
                </Typography>
            </Box>
            
        </Stack>
    </Paper>
  );
}

export default InterventionResultBlock;
