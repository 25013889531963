import React, { createContext, useState, useContext, memo, useMemo, useEffect } from 'react';
import SaveModal from '../documents/SaveModal';
import SaveAsModal from '../documents/SaveAsModal';
import OpenModal from '../documents/OpenModal';
import DiscardModal from '../documents/DiscardModal';
import axios from 'axios';
import { useDocumentManagement } from './DocumentManagementContext';
import { Alert, Snackbar } from '@mui/material';

// Create the ChartServiceContext
const ChartServiceContext = createContext();

/*
        "start_month",
        "initial_pop",
        "duration_t",
        "step_size",
        "initial_ster_prop",
        "carry_cap",
        "proprop_femaleemale",
        "adult_surv",
        "infant_surv",
        "avg_litter_size",
        "avg_litters_yearly",
        "net_migration_prop",
        "monthly_sters",

*/

// Create a provider component
export const ChartServiceProvider = ({ initialState, serviceApiBase, parentDocHash,children }) => {

  
  // We need this component to be within the DocumentManagementProvider
  const {setActiveDocumentState} = useDocumentManagement();

    // This state will hold the ChartService
  const [ChartService, setChartService] = useState({
    showVariableKeys: false
  });

  const initializeProject = (projectState) => {
    setInitialDocumentState(projectState);
    setDocumentChanged(false);
  }

  // (Public/Private) When anything happens in a doc, set this flag to true. This is our "dirty" flag.
  const [isLoading, setIsLoading] = useState(false); 

  // (Private) Error logging
  const [messages, setMessages] = useState([]);
  const handleMessageClose = (index) => (event, reason) => {
    setMessages(messages.filter((msg, i) => i !== index));
  };
  const addNewMessage = (message, severity) => {
    setMessages([...messages, {
      message: message,
      severity: severity
    }]);
  }

  // (Private) When anything happens in a doc, set this flag to true. This is our "dirty" flag.
  const [documentChanged, setDocumentChanged] = useState(true);
  
  // (Private) The default state or opened state of a document goes here. 
  // When something is loaded, this gets set.
  const [initialDocumentState, setInitialDocumentState] = useState({}); 


  // (Private) The default state or opened state of a document goes here. 
  // When something is loaded, this gets set.
  const [currentDocumentId, setCurrentDocumentId] = useState(null); 

  // (Public) The state, eg. set of variables that really matter. This is what gets saved.
  const [activeVariableSet, setActiveVariableSet] = useState(initialState);

  const activeVariableSetMemo = useMemo(() => activeVariableSet, [activeVariableSet]);

  // Combined list of the inputs along with the results
  const [compoundInterventionData, setCompoundInterventionData] = useState([]);



  /* Chart Data - Covers both charts for population data 
  eg. 
  {
    "month_number": [
        {start_month},
        ...
        {duration_t}
    ],
    "sterilisation_proportion": [
        0.0,
        ...
    ],
    "sterilised_population_count": [
        0.0,
        ...
    ],
    "total_population_count": [
        2000.0,
        ...
    ]
}
  */

  const initialChartData = {
      month_number: [],
      sterilisation_proportion: [],
      sterilised_population_count: [],
      total_population_count: []
  }

  const [chartData, setChartData] = useState(initialChartData);


  // When parentDocHash.uuid changes, load the new Data from parentDocHash.doc 
  useEffect(() => {
    if(parentDocHash.uuid) {
      const docDecoded = JSON.parse(parentDocHash.doc.json_state);
      console.log("??????? ", docDecoded); 
      setActiveVariableSet(docDecoded);
      loadChartData(docDecoded);
    } else if (window.location.hash.length == 0 && parentDocHash.uuid == null) {
      loadChartData(initialState);
    }
  }, [parentDocHash.uuid]);

  
  const [prevChartData, setPrevChartData] = useState(initialChartData);
  const chartDataMemo = useMemo(() => chartData, [chartData]);
  const prevChartDataMemo = useMemo(() => prevChartData, [prevChartData]);


  // Public
  const updateVariables = (newVariables) => {
    setActiveVariableSet(newVariables);
    setActiveDocumentState(newVariables);
  }

  // Get the chart data
  const loadChartData = (data=null) => {
    const inputs = data ? data : activeVariableSet;
    setIsLoading(true);
    axios.post(`${serviceApiBase}/graph`,inputs)
      .then(res => {
        console.log(res.data);

        // TODO Hide ghosting of previous chart data if lengths don't match
        if(res.data.month_number.length ==  chartData.month_number.length ) { 
          setPrevChartData(chartDataMemo);
        } else {
          setPrevChartData(res.data);
        }
        setChartData(res.data);
        setIsLoading(false);


        // Combine the results into the compoundInterventionData state
        const results = [];
        activeVariableSet.interventions.reduce((acc, intervention) => {
          if(intervention.type == "find_monthly_sters") {
            results.push({
              ...intervention,
              monthly_sters: res.data?.monthly_sters[acc]
            });
            return acc+1;
          }
          return acc;
        },0);
        console.log(">>>>> INTERVENTION DATA");
    
        console.log(results)
        setCompoundInterventionData(results);
    

      })
      .catch(err => {
        console.log(err);
        addNewMessage("Error loading chart from current data", 'error')
        // setChartData(initialChartData);
        setIsLoading(false);
      });
  }
  
  // (Public) - Function to update a specific setting
  const setSetting = (key, value) => {
    console.log(key,value);
    setChartService((prevChartService) => ({
      ...prevChartService,
      [key]: value,
    }));
  };

  // Determine what happens based on the current state
  const onConfirm = () => {

  }

  // When the UUID changes, load the new data into the application
  // useEffect(() => {

  //   console.log("Checking to see if we need to reload anything");
  //   if (parentDocHash.doc.json_state) {
  //     console.log("We have a JSON state variable");
  //     const jsonStuff = JSON.parse(parentDocHash.doc.json_state);
  //     console.log(jsonStuff)
  //     setActiveVariableSet(jsonStuff);

  //     console.log("Loading...");
      
  //     loadChartData();

  //   } else {
  //     console.log("We do not JSON state variable");
  //   }
  // }, [parentDocHash.uuid]);
  
  return ( 
    <ChartServiceContext.Provider value={{ 
        ChartService,
        updateVariables,
        activeVariableSet,
        activeVariableSetMemo,
        compoundInterventionData,
        isLoading,

        // Graph
        chartDataMemo,
        prevChartDataMemo,
        loadChartData,

        // Settings
        setSetting,

        // Document Management
        
        }}>
      {children}
      {
        messages.map((error, index) => {
          return (
            <Snackbar
              key={index}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={true}
              autoHideDuration={6000}
              onClose={handleMessageClose(index)}
            >
              <Alert
                onClose={handleMessageClose(index)}
                severity={error.severity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {error.message}
              </Alert>
            </Snackbar>
          )
        })
      }
    </ChartServiceContext.Provider>
  );
};

// Custom hook to use the ChartService context
export const useChartService = () => {
  const context = useContext(ChartServiceContext);
  if (!context) {
    throw new Error('useChartService must be used within a ChartServiceProvider');
  }
  return context;
};