import { Box, Button, Buttonn, CircularProgress, Divider, Fab, FormGroup, IconButton, Input, List, ListItem, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText, Modal, Pagination, Paper, Stack, TextField, Typography } from "@mui/material";
import AnimatedModal from "../AnimatedModal";
import { useEffect, useState } from "react";
import { AutoGraphOutlined, Delete, DocumentScanner, HighlightOff, RemoveCircle, Search, StopCircle } from "@mui/icons-material";
import HumanTimestamp from "../shared/HumanTimestamp";
import { useChartService } from "../contexts/ChartServiceContext";
import { useDocumentManagement } from "../contexts/DocumentManagementContext";
import { useAuth0 } from "@auth0/auth0-react";

const AccessWarningModal = ({onChooseDocument, open=false, onClose=() => {}}) => {

    useEffect(() => {
        if(open == true) {
        }
    },[open]);

    const {logout} = useAuth0();

    return (
        <AnimatedModal
            sx={{displayPrint: 'none'}}
            open={open}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            title="You don't have access to this application"
        >
        <Stack spacing={4}>
            <Typography variant="h6" component="h6" gutterBottom>
                Please complete account setup if you believe you do have access. Log into the hub and join an organization that has access to this app.
            </Typography>
            <Divider/>
            <Button size="large" variant="contained" onClick={() => window.open(process.env.REACT_APP_HUB_CLIENT_URL, '_blank')}>Launch Hub</Button>
            <Button size="large" variant="text" onClick={() => logout({ logoutParams: { returnTo: window.location.origin } }) } >Sign Out</Button>

        </Stack>
    </AnimatedModal>
    )
}

export default AccessWarningModal;