import { Box, CircularProgress, Divider, Paper, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { graphColors } from '../Theming';
import StyledChart from './StyledChart';
import { useDocumentManagement } from '../contexts/DocumentManagementContext';
import { useChartService } from '../contexts/ChartServiceContext';
import { useSettings } from '../contexts/SettingsContext';
import InterventionResultBlock from './InterventionResultBlock';

const colors = {
  footer: "#ccc",
  header: "#ccc",
  body: "#fff",
  sidePanel: "#fff",
}

function PopModelling({prevVariables,variables,isMobile}) { 

  // ChartDataMemo should never be null - Use the default document
  const { chartDataMemo, prevChartDataMemo, isLoading, activeVariableSetMemo, compoundInterventionData } = useChartService();

  const {settings} = useSettings();
  const {showPreviousGraph} = settings;

  const [windowWidth, setWindowWidth]   = useState(!isMobile ? window.innerWidth-400 : window.innerWidth);
  
  const subtractFromHeight = compoundInterventionData.length > 0 ? 100 : 0;
  const [windowHeight, setWindowHeight] = useState((window.innerHeight/3) - subtractFromHeight);

  const handleResize = () => {
    setWindowWidth( !isMobile ? window.innerWidth-400 : window.innerWidth);
    setWindowHeight(((window.innerHeight/3) - subtractFromHeight));
  }

  useEffect(() => {
    window.addEventListener(
        "resize", 
        handleResize,
        false
    );
  }, [isMobile]);

  // Show loading if not ready
  if(chartDataMemo == null || isLoading) {
    return (
      <Box 
        className="PopModelling" display={'flex'} 
        justifyContent={'center'}
        alignItems={'center'}
        style={{marginTop: '50px', flex: 1,  height: 'calc(100% - 100px)'}}
      > {/* PopModelling Body */}
        <CircularProgress/>
      </Box>
    )
  }

  if(chartDataMemo.month_number.length == 0) {
    return (
      <Box 
        className="PopModelling" display={'flex'} 
        justifyContent={'center'}
        alignItems={'center'}
        style={{marginTop: '50px', flex: 1,  height: 'calc(100% - 100px)'}}
      > {/* PopModelling Body */}
        <Typography variant="h6" component="h2" gutterBottom color={'text.secondary'}>
          Not enough data to graph. Please adjust parameters and recalculate.
        </Typography>
      </Box>
    )
  }

  // Optionally spread this if showPreviousGraph is true
  const previousCharts1 = showPreviousGraph ? [{
    label: "Population at Month (Last Run)",
    data: prevChartDataMemo.total_population_count,
    color: `${graphColors.total_population_count}33`
  },
  {
    label: "Sterilized Pop. Count (Last Run)",
    data: prevChartDataMemo.sterilised_population_count,
    color: `${graphColors.sterilised_population_count}33`
  }] : []

  const previousCharts2 = showPreviousGraph ? [
    {
      data: prevChartDataMemo.sterilisation_proportion,
      color:`${graphColors.sterilisation_proportion}33`
    },
  ] : [];

  

  return (

        <Box className="PopModelling" display={'flex'} style={{marginTop: '50px', flex: 1,  height: 'calc(100% - 100px)'}}> {/* PopModelling Body */}
          <Box style={{flex: 1, justifyItems: 'center', justifyContent:'center', alignContent:"center"}}> {/* PopModelling Main */}
            {
              /* Total Population at time T (months) */
            }
            
            <StyledChart isMobile={isMobile}
              xAxisData={chartDataMemo.month_number}
              yAxisLabel={"Number"}
              yMin={0}
              yax={null}
              series={[
                // Previous data from last calculation
                ...previousCharts1,
                                
                // New data
                {
                  label: "Population at Month",
                  data: chartDataMemo.total_population_count,
                  color: `${graphColors.total_population_count}FF`
                },
                {
                  label: "Sterilized Pop. Count",
                  data: chartDataMemo.sterilised_population_count,
                  color: `${graphColors.sterilised_population_count}FF`
                },



              ]}
              width={windowWidth}
              height={windowHeight}
            />

            {
              /* Number Sterilized Dogs at Time T (months) */
            }
            <StyledChart isMobile={isMobile}
              yMin={0}
              yMax={100}
              xAxisData={chartDataMemo.month_number}
              yAxisLabel={"Sterilized Proportion (%)"}
              series={[

                // Previous data from the last chart
                ...previousCharts2,
                // New data
                {
                  data: chartDataMemo.sterilisation_proportion,
                  color: `${graphColors.sterilisation_proportion}FF`
                },

              ]}

              yAxis={[{ label: 'Percent %', min:0, max: 100 }]}
              xAxis={[{ 
                  data: chartDataMemo.month_number
                }]}
              
              width={windowWidth}
              height={windowHeight}
            />
            {
              compoundInterventionData.length > 0 &&
              <Box>
                <Box>
                  <Typography textAlign="center" variant="body1" gutterBottom color={'text.secondary'}>
                    Interventions
                  </Typography>
                </Box>
            
                <Box style={{display: 'flex', gap: '10px', padding:'5px', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px', maxWidth:windowWidth, overflowX:'scroll'}}>
                  
                  {
                    /* Do not display this if a recalculation of charDataMemo is needed - inputs at calculation time should match inputs now. */
                    compoundInterventionData.map( (intervention) => {
                      return (
                        <InterventionResultBlock intervention={intervention}/>
                      )
                    })
                  }
                </Box>
              </Box>
            }

          </Box>
        </Box>
  );
}

export default PopModelling;
