import React, { createContext, useState, useContext, memo, useMemo, useEffect } from 'react';
import SaveModal from '../documents/SaveModal';
import SaveAsModal from '../documents/SaveAsModal';
import OpenModal from '../documents/OpenModal';
import DiscardModal from '../documents/DiscardModal';
import axios from 'axios';
import { Alert, Backdrop, Snackbar } from '@mui/material';
import AnimatedModal from '../AnimatedModal';
import AccessWarningModal from '../documents/AccessWarningModal';
import DocumentErrorModal from '../documents/DocumentErrorModal';


// Create the DocumentManagementContext
const DocumentManagementContext = createContext();

/*
        "start_month",
        "initial_pop",
        "duration_t",
        "step_size",
        "initial_ster_prop",
        "carry_cap",
        "proprop_femaleemale",
        "adult_surv",
        "infant_surv",
        "avg_litter_size",
        "avg_litters_yearly",
        "net_migration_prop",
        "monthly_sters",
*/


// Create a provider component
export const DocumentManagementProvider = ({ 
  onMakeAuthenticatedRequest, // The function from the wrapper that makes an authenticated request
  serviceApiBase,             // The base URL for the document
  onMakeDocumentURL,          // The function that constructs the URL for recalling this document
  onFormatDocTitle,           // The function that constructs the title of this document
  documentsPath,              // GET path for list of available documents
  
  // Document handling callbacks and actions
  defaultDocumentState,
  defaultDocument,
  onDocumentLoad,

  // Constraints
  maxFilenameLength,

  // Necessary for posts:
  appSlug,

  // Access control - block and show a warning if the user can't access stuff
  canAccessData,

  // Access control setup message
  accessControlSetupMessage,

  children 
}) => {

  const getUserProfile = (callback) => {

  }


  // Not happy with this, but it should work

  // Detect a change of the ID and load the document
  useEffect(() => {
    const lastDocumentId = activeDocumentInstance.uuid;
    const newDocumentId = window.location.hash;
    
    if(newDocumentId.length > 10) {
      console.log(">>>>> VALID-ISH DOCUMENT. LOADING", newDocumentId)
      loadDocument(newDocumentId.replace('#',''));
    }
    
  },[window.location.hash]);
  
  const loadDocument = (documentUUID) => {
    if(documentUUID) {
      const url = `${serviceApiBase}${documentsPath}${documentUUID}`;
      console.log("LOADING DOCUMENT", url)
      onMakeAuthenticatedRequest(url, {method:'GET'})
      .then((d) => {
        console.log("LOADED DOCUMENT", d.data)
        setActiveDocumentInstance(d.data)
        onDocumentLoad(d.data)
      })
      .catch(e => {
        console.error(e)
        addNewMessage("Could not load document", 'error')
        setActiveDocumentInstance(defaultDocument);
        setDocumentErrorWindowOpen(true);
      })
    } else {
      setActiveDocumentInstance(defaultDocument);
    }
  }

  
  // // Simulate a network request with a 500ms delay
  // const fakeResponse = (data) => {
  //   return new Promise((resolve) => {
  //     setTimeout(() => resolve(data), 500);
  //   });
  // };
  
  // This state will hold the DocumentManagement
  const [documentManagement, setDocumentManagement] = useState({
    showVariableKeys: false
  });

  // (Private) When anything happens in a doc, set this flag to true. This is our "dirty" flag.
  const [documentChanged, setDocumentChanged] = useState(true);

  // (Private) When anything happens in a doc, set this flag to true. This is our "dirty" flag.
  const [documentErrorWindowOpen, setDocumentErrorWindowOpen] = useState(false);


  // (Private) Messages for errors and successes here
  const [messages, setMessages] = useState([]);
  const handleMessageClose = (index) => (event, reason) => {
    setMessages(messages.filter((msg, i) => i !== index));
  };
  const addNewMessage = (message, severity) => {
    setMessages([...messages, {
      message: message,
      severity: severity
    }]);
  }
  
  // (Private) The default state or opened state of a document goes here. 
  // When something is loaded, this gets set.
  const [currentDocumentId, setCurrentDocumentId] = useState(null); 

  // (Public) Basically just the filename for now
  const [activeDocumentInstance, setActiveDocumentInstance] = useState(defaultDocument);


  const updateDocumentInstance = (key, value) => {
    console.log(">>>>>>> UPDATING DOC INSTANCE", key, value)
    const keys = ['name', 'json_state', 'json_extras']; 
    if(keys.includes(key)) {
      setActiveDocumentInstance({
        ...activeDocumentInstance,
        [key]: value
      })
      if(key == 'name' && value.length <= maxFilenameLength) {
        onFormatDocTitle(value)
      }
    }

  }

  // // (Public) Set the active working document in progress, prior to save
  const [activeDocumentState, setActiveDocumentState] = useState(defaultDocument);
  
  // (Private) Save button with a prompt to the model name
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  
  // (Private) Save button with a prompt to the model name (save as copy)
  const [saveAsModalOpen, setSaveAsModalOpen] = useState(false);

  // (Private) If we have a dirty document, prompt to save or discard
  const [discardModalOpen, setDiscardModalOpen] = useState(false);

  // (Private) Open a new model
  const [openModalOpen, setOpenModalOpen] = useState(false);
  const [documentsAvailable, setDocumentsAvailable] = useState({});

  // (Public) Whether the document is saving
  const [isSaving, setIsSaving] = useState(false);

  // (Public) Whether the document is loading
  const [isLoading, setIsLoading] = useState(false);

  // (Public) Exposes the loading state
  const documentIsLoading = () => {
    return isLoading;
  }

  // (Public) Exposes the loading state
  const documentIsSaving = () => {
    return isSaving;
  }

  const [afterDiscardState, setAfterDiscardState] = useState(defaultDocument);

  // // (Public) Process to save the active document
  // const updateDocument = (doc) => {
  //   console.log("UPDATING DOCUMENT", doc)
  //   setSaveModalOpen(false);
  //   setSaveAsModalOpen(false);
  //   setDiscardModalOpen(false);
  //   setOpenModalOpen(false);  
  //   setDirtyDocFlag();
  // }


  // These functions are set when the modal is opened by a file menu... For example the one in NavBar
  /* Double arrows are not a typo. They are used to indicate a function that returns a function */
  const [onDiscardCallback, setOnDiscardCallback] = useState(() => () => console.log("No discard function defined"));
  const [onSaveAsCallback, setOnSaveAsCallback] = useState(() => () => console.log("No save function defined"));

  // (Private) - Reset the flag after load or save
  const resetDirtyDocFlag = () => {
    setDocumentChanged(false);
  }

  // (Private) - Set a flag indicating the document has been changed
  const setDirtyDocFlag = () => {    console.log("Document Changed")
    setDocumentChanged(true);
  }

  // (PUBLIC) Set up the save 
  const setUpSave = ({newDocument=false, data= {}, data_extras={}, onSave=() => {console.log("No save function defined")}}) => {
    setOnSaveAsCallback(() => () => onSave())
    const newState = {...activeDocumentInstance, json_state: data, json_extras: data_extras}
    setActiveDocumentInstance(newState)
    saveActiveDocument(newState, false);
  }

  // (Public) - Called by file menu to open the pop up
  const openSaveAsModal = ({data= {}, data_extras={}, onSave=() => {console.log("No save function defined")}}) => {
    // Set the callback 
    setOnSaveAsCallback(() => () => onSave())
    
    const newState = {...activeDocumentInstance, json_state: data, json_extras: data_extras}
    setActiveDocumentInstance(newState)
    console.log("NEW STATE OF SAVE", newState)
    setSaveAsModalOpen(true)
  }

  // (Public) - Called by file menu to open the pop up
  const openOpenModal = (searchTerm = '') => {
    var adjustedTerm = searchTerm;
    if(searchTerm.length < 4) {
      adjustedTerm = '';
    }
    setOpenModalOpen(true)
    setIsLoading(true)
    const options = {
      method: 'GET',
    }
    onMakeAuthenticatedRequest(serviceApiBase+documentsPath+`?app_slug=${appSlug}&searchTerm=${adjustedTerm}`)
    .then((d) => {
      setDocumentsAvailable(d.data)
      setIsLoading(false)
    })
    .catch(e => {
      addNewMessage("Error loading document list", 'error')
    })
  }

  // const handleOnSaveError = (e) => {
  //   onSaveError(e);
  // }

  // // (PRIVATE) When saving a document, execute the callback
  // const handleOnSaveDocument = (doc) => {
  //   onSaveDocument(doc);
  // }

  // // (PRIVATE) When loading a document different than the current one 
  // const handleOnLoadNewDocument = (doc) => {
  //   onLoadNewDocument(doc);
  // }

  // // PRIVATE) When discarding a document, execute the callback
  // const handleDiscardDocument = (doc) => {
  //   onDiscardDocument(doc);
  // }

  // (Private) - If discard is opened
  const openDiscardPrompt = (params) => {
    setDiscardModalOpen(true)
    setOnDiscardCallback(()=>() => params.onDiscard());
  }

  // (Public) - Called by file menu to open the pop up
  const openSaveModal = (doc) => {
    console.log(doc);
    setSaveAsModalOpen(true)
  }

  // (Public) - Function to update a specific setting
  const setSetting = (key, value) => {
    console.log(key,value);
    setDocumentManagement((prevDocumentManagement) => ({
      ...prevDocumentManagement,
      [key]: value,
    }));
  };

  // (Private) - Only called by Child Components
  const onOpenDocument = (documentId) => {
    console.log("OPENING DOC!")
    if(documentChanged) { 
      // setOpenModalOpen(false);
      openDiscardPrompt({onDiscard: () => window.location.hash = documentId});
    } else {
      //Trigger document open
    }
  }

  // Determine what happens based on the current state
  const onConfirm = () => {

  }

  const validateDocumentName = (docName, onSuccess, onFail) => {
    if(docName && docName.length > 5 && docName.length < 100) {

      onMakeAuthenticatedRequest(serviceApiBase+`${documentsPath}namecheck?name=${docName}&app_slug=${appSlug}`, {method:'GET'})
      .then((d) => {
        console.log(d.data.match_found);
        if(d.data.match_found == false) {
          onSuccess();
        } else {
          onFail(false);
        }
      })
      .catch((e) => {
        onFail(e);
      })
    } return false;
  }

  

  // (Public) - Save the document
  const saveActiveDocument = (docToSave, createNewDocument) => {

    const doUpdate = activeDocumentInstance.uuid != null ? true : false;
    console.log("SAVING ACTIVE DOC: ", docToSave, activeDocumentInstance)
    setActiveDocumentState(docToSave);
    setIsLoading(true)

    console.log("XXXXXXXXXXXXXXXXXXXXX", typeof docToSave.json_state)
    
    const payload = {
      name: activeDocumentInstance.name,
      json_state: typeof docToSave == 'string' ? docToSave : JSON.stringify(docToSave.json_state),
      app_slug: appSlug
    }
    setIsSaving(true);
    const url = createNewDocument? 
                serviceApiBase+documentsPath : 
                serviceApiBase+documentsPath+activeDocumentInstance.uuid;
    const method = createNewDocument ? 'POST' : 'PUT';

    
    onMakeAuthenticatedRequest(url, {method:method, data: payload})
    .then((d) => {
      setActiveDocumentState(d.data.json_state)
      setActiveDocumentInstance(d.data)
      setIsLoading(false)
      setIsSaving(false);
      addNewMessage( 'Successfully saved document', 'success');
      handleSaveCallback()
      setSaveAsModalOpen(false);

      // Change the URL  to the new document URL
      window.location.hash = onMakeDocumentURL(d.data.uuid);

    })
    .catch(e => {
      addNewMessage("Error saving document", 'error')
      setIsLoading(false)
      setIsSaving(false);
    })

    // console.log(activeDocumentState)

    // axios.post("")
  }

  const handleSaveCallback = () => {
    onSaveAsCallback();
    addNewMessage("Document saved", 'success');
  }


  const handleOnDiscardCallback = () => {
    console.log("DISCARD HAS BEEN CLICKED");
    onDiscardCallback();
    setDiscardModalOpen(false);
  }

  const handleOnSaveAsCallback = () => {
    console.log(">>>>>>> CALLING SAVE AS CALLBACK")
    setSaveAsModalOpen(false);
    onSaveAsCallback();
  }


  // Event hooks
  // On update document title
  useEffect(() => {
    document.title = onFormatDocTitle(activeDocumentInstance.name)
  }, [activeDocumentInstance?.name])


  
  return ( 
    <DocumentManagementContext.Provider value={{ 
        documentManagement, 

        defaultDocumentState,
        defaultDocument,
        activeDocumentInstance, setActiveDocumentInstance, updateDocumentInstance,
        activeDocumentState, setActiveDocumentState,
        
        validateDocumentName,

        // Pending States
        isSaving, setIsSaving,
        isLoading, setIsLoading,

        // Dialog & Opening
        documentsAvailable,

        // Doc state management
        documentIsLoading,
        documentIsSaving,
        documentChanged,

        // Doc actions
        openSaveAsModal,
        setUpSave,
        openOpenModal,
        openSaveModal,
        openDiscardPrompt,
        saveActiveDocument,

        // Settings
        setSetting,
        }}>
      {children}

      {/* Probably will never appear */}
      <SaveModal onSave={handleOnSaveAsCallback} open={saveModalOpen} onClose={() => setSaveModalOpen(false)}/> {/* Should only appear on a new document, otherwise just save it. */}
      <OpenModal 
              onDisplayConfirmModal={() => setDiscardModalOpen(true)} 
              open={openModalOpen} 
              onClose={() => setOpenModalOpen(false)} 
              onChooseDocument={(doc) => onOpenDocument(doc)}/>  {/* Show a list of documents previously saved */}
      <SaveAsModal onSave = {() => saveActiveDocument({...activeDocumentInstance, doc:activeDocumentState}, true)} open={saveAsModalOpen} onClose={() => setSaveAsModalOpen(false)}/> {/* Similar to Save Modal*/}
      <DiscardModal 
            onDiscard={handleOnDiscardCallback}
            onConfirm={() => onConfirm()}  
            open={discardModalOpen} 
            onClose={() => setDiscardModalOpen(false)}
      /> {/* Similar to Save Modal*/}

      {
        messages.map((error, index) => {
          return (
            <Snackbar
              key={index}
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={true}
              autoHideDuration={6000}
              onClose={handleMessageClose(index)}
            >
              <Alert
                onClose={handleMessageClose(index)}
                severity={error.severity}
                variant="filled"
                sx={{ width: '100%' }}
              >
                {error.message}
              </Alert>
            </Snackbar>
          )
        })
      }
      {
        canAccessData == false && !isLoading &&
        <Backdrop open={true} sx={{backdropFilter: 'blur(10px)'}}>
          <AccessWarningModal
            open={true}
            message={accessControlSetupMessage}/>
        </Backdrop>
      }
      
      <DocumentErrorModal
        open={documentErrorWindowOpen}
        onClose={() => {
          setDocumentErrorWindowOpen(false);
          window.location.hash = "";
        }} 
      />
    </DocumentManagementContext.Provider>
  );
};

// Custom hook to use the DocumentManagement context
export const useDocumentManagement = () => {
  const context = useContext(DocumentManagementContext);
  if (!context) {
    throw new Error('useDocumentManagement must be used within a DocumentManagementProvider');
  }
  return context;
};