import { Avatar, Box, Button, ButtonGroup, Card, CardContent, CardMedia, CircularProgress, ClickAwayListener, Container, createTheme, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Stack, Typography, useMediaQuery, useTheme } from "@mui/material";
import NavButton from "./NavButton";
import {getTabName, tabs} from "./Constants";
import { ArrowDropDown, CatchingPokemonSharp, Menu } from "@mui/icons-material";
import { useEffect, useRef, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import App from "./App";
import { ThemeProvider } from "@emotion/react";
import image from "./images/backdrop.jpg";
import { DocumentManagementProvider } from "./contexts/DocumentManagementContext";
import { ChartServiceProvider } from "./contexts/ChartServiceContext";
import axios from "axios";
import Logo from "./Logo";


const defaultDocDetails = {
    id:          null,
    name:        'New Model', // String(256)
    updated_at:  null,        // datetime
    created_at:  null,        // datetime
    json_state:  null,        // Document default state	- override by setting this at time of save
    app_slug:    null,        // Accessor for doc within app
    json_extras: null,        //	Extra non-document stuff to save - future proofing - Can be null
    organization_id: null,	  // Get current user's org when saving
    created_by_user_id: null  // Current user's id when saving 
  
}

  
const defaultDocState = {
        initial: {
          start_month: 0,            // Initial time  
          initial_pop: 2000,         // Initial total population  
          duration_t: 18,    // Time over which to model (in months)  
          step_size: 1,             // Step size for Runge Kutta method (1 recommended)  
          carry_cap: 10000,         // Carrying capacity of environment  
          prop_female: 0.5,         // Proportion of population that are female (0-1)  
          adult_surv: 0.99,        // Adult survivability rate (0-1)  
          infant_surv: 0.4,         // Infant survivability rate (0-1)  
          avg_litter_size: 6,             // Average litter size  
          avg_litters_yearly: 2/12,        // Reproduction rate/average number of litters (monthly)  
          initial_ster_prop: 0,            // Initial number of sterilised dogs  
          net_migration_prop: 0,             // Net migration rate (positive into environment, negative out) (0-1)  
          monthly_sters: 250,         // Number of sterilisations taking place each month  
        },
        interventions: [
          // {
          //   desired_S_N: 0.5,    // Proportion of sterilized dogs
          //   t0: 0,            // Initial time  
          //   initial_pop: 2000,         // Initial total population  
          //   duration_t: 18,    // Time over which to model (in months)  
          //   h: 1,             // Step size for Runge Kutta method (1 recommended)  
          //   k: 10000,         // Carrying capacity of environment  
          //   prop_female: 0.5,         // Proportion of population that are female (0-1)  
          //   adult_surv: 0.99,        // Adult survivability rate (0-1)  
          //   infant_surv: 0.4,         // Infant survivability rate (0-1)  
          //   l: 6,             // Average litter size  
          //   avg_litters_yearly: 2/12,        // Reproduction rate/average number of litters (monthly)  
          //   initial_ster_prop: 0,            // Initial number of sterilised dogs  
          //   m: 0,             // Net migration rate (positive into environment, negative out) (0-1)  
          //   n_s: 250,         // Number of sterilisations taking place each month  
          // },
          // {
          //   desired_S_N: 0.5,    // Proportion of sterilized dogs
          //   t0: 0,            // Initial time  
          //   initial_pop: 2000,         // Initial total population  
          //   duration_t: 18,    // Time over which to model (in months)  
          //   h: 1,             // Step size for Runge Kutta method (1 recommended)  
          //   k: 10000,         // Carrying capacity of environment  
          //   prop_female: 0.5,         // Proportion of population that are female (0-1)  
          //   adult_surv: 0.99,        // Adult survivability rate (0-1)  
          //   infant_surv: 0.4,         // Infant survivability rate (0-1)  
          //   l: 6,             // Average litter size  
          //   avg_litters_yearly: 2/12,        // Reproduction rate/average number of litters (monthly)  
          //   initial_ster_prop: 0,            // Initial number of sterilised dogs  
          //   m: 0,             // Net migration rate (positive into environment, negative out) (0-1)  
          //   n_s: 250,         // Number of sterilisations taking place each month  
          // }
        ]
        // N_t               // Total population at time t  
        // S_t               // Number of sterilised dogs at time t
}

const ProtectedAppWrapper = () => {

    // The default
    const [docTrackState, setDocTrackState] = useState(null);
    const [parentDocHash, setParentDocHash] = useState({uuid: null, doc: defaultDocState});

    const [loading, setLoading] = useState(true);

    const [appAccess, setAppAccess] = useState(null);

    const {isAuthenticated, loginWithRedirect, isLoading, getAccessTokenSilently} = useAuth0();

    // Size Constraints
    const theme = useTheme();
    const bigEnough = useMediaQuery(theme.breakpoints.up('sm'));
  

    useEffect(() => { 
        setDocTrackState(docTrackState);
        checkAppAccess();
    }, [docTrackState]);

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            console.log( ">>>>>>>>>", isAuthenticated, !isLoading)
            checkAppAccess();
        }
    }, [isLoading, isAuthenticated])
      
    
    const makeAuthenticatedRequest = async (url, options = {}) => {
        if (!isAuthenticated) {
            throw new Error("User is not authenticated");
        }
        try {
            const token = await getAccessTokenSilently();  // Wait for the token
            const response = await axios(url, {
            ...options,
            headers: {
                ...options.headers,
                Authorization: `Bearer ${token}`,
            }
            });
            
            return response;  // Return the full response
        } catch (error) {
            console.error("Request failed", error);
            throw new Error("Request failed", error);  // Throw an error if it fails
        }
        };

    const handleDocumentLoad = (doc) => {
        setParentDocHash({uuid: doc.uuid, doc: doc});
        // Change the active document hash to reload a new one. This should be the only thing that triggers this.
    }

    const checkAppAccess = () => {
        console.log(" Check API Access")
        const slug = 'dogpop-v1';
        if(isAuthenticated && !isLoading) {
            makeAuthenticatedRequest(process.env.REACT_APP_HUB_API_URL+`/api/services/apps/${slug}`)
            .then((d) => {
                if(d.data == true) {
                    console.log("APP ACCESS", d.data);
                    setAppAccess(true);
                    setLoading(false);
                } else {
                    setAppAccess(false);
                    setLoading(false);
                }
            }).catch((e) => {
                console.log("APP ACCESS ERROR", e);
                setAppAccess(false);
                setLoading(false);
            }); 
        }
    }

    console.log(isAuthenticated, isLoading);

    
    return (

            <Box
                height={'100%'}
            >
                {
                    loading && isAuthenticated && appAccess == null &&
                    <Box style={{height: '100%',alignContent: 'center', justifyItems: 'center',
                        textAlign: 'center'}}>
                        <Typography variant="body1">Loading...</Typography>
                        <CircularProgress/>
                    </Box>
                }
                {!loading && isAuthenticated &&
                    <DocumentManagementProvider
                        canAccessData={appAccess == true || appAccess == null}
                        onDocumentLoad={(doc) => handleDocumentLoad(doc)}
                        appSlug={'dogpop-model'}
                        onFormatDocTitle={(docTitle) => {
                            return `${docTitle} - Dog Population Modeling Tool`
                        }}
                        serviceApiBase={process.env.REACT_APP_HUB_API_URL}
                        onMakeDocumentURL={(document_id, service=window.location.origin, fullUrl=false)=>{
                            if(fullUrl) {
                                return `${service}/#${document_id}`
                            } else {
                                return `#${document_id}`
                            }
                        }}
                        documentsPath={'/api/services/documents/'}
                        onMakeAuthenticatedRequest={makeAuthenticatedRequest}
                        defaultDocument={defaultDocDetails}
                        defaultDocumentState={defaultDocState}

                        maxFilenameLength={69}
                        /*
                            In dependent service providers, use this:
                            
                            onDocumentChanged={(doc) => {
                                console.log("Document changed", doc)
                            }}
                         */
                    >
                        <ChartServiceProvider
                            // onUpdateVars={ (vars) => setActiveDocumentState(vars) }
                            parentDocHash={parentDocHash} // Only changes when doc update is triggered
                            onParentDocChange={(doc) => { // Don't use this. Use defaultDocState.
                                console.log("Document changed", doc)
                            }}
                            // parentDocHash={parentDocHash} // Only changes when doc update is triggered
                            serviceApiBase={process.env.REACT_APP_DOGPOP_API_URL}
                            initialState={parentDocHash.doc}
                        >
                            <App/>
                        </ChartServiceProvider>
                    </DocumentManagementProvider>
                }
                {!isLoading && !isAuthenticated &&
                    <Box 
                        display={'flex'} 
                        height={'100%'} 
                        width={'100%'}
                        alignContent={"center"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        justifyItems={"center"}
                    >
                            <Container sx={{maxWidth: 'md'}}>
                                <Card sx={{display:'flex', flexDirection: bigEnough ? 'row' : 'column'}}>
                                    {
                                        bigEnough &&
                                        <CardMedia
                                            component="img"
                                            media="picture"
                                            alt="Contemplative Reptile"
                                            sx={{display:'block', position:'relative', minWidth:'100px', maxWidth:'5%', height:'500px'}}
                                            image={image}
                                        
                                        />
                                    }
                                    <CardContent sx={{flex:1, display:"flex", alignContent:'center', alignItems:'center',justifyContent:'center', justifyItems:'center'}}>
                                        <Stack spacing={2}>
                                            <Box>
                                                <Logo/>
                                            </Box>
                                            <Typography variant="h3">Sign In</Typography>
                                            <Typography variant="subtitle2">You've stumbled on a private application for our trusted partners.</Typography>

                                            <Button size="large" onClick={()=>loginWithRedirect()} variant="contained">Sign In</Button>
                                            <Button variant="text">Learn More & Sign Up</Button>
                                        </Stack>
                                        
                                    </CardContent>

                                    {
                                        !bigEnough &&
                                        <CardMedia
                                            component="img"
                                            media="picture"
                                            alt="Contemplative Reptile"
                                            sx={{display:'block', position:'relative', minWidth:'100px', height:'150px'}}
                                            image={image}
                                        
                                        />
                                    }
                                </Card>

                            </Container>
                    </Box>
                }
            </Box>
    )
}

export default ProtectedAppWrapper;