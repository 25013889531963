import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import ProtectedAppWrapper from './ProtectedAppWrapper';
import { createTheme, ThemeProvider, CssBaseline } from '@mui/material';
import { primary, secondary } from './Theming';
import bgimg from "./images/pawprints-px.png";

const theme = createTheme({
  palette: {
    primary: {
      main: primary.neutral,
    },
    secondary: {
      main: secondary.neutral,
    },
    background: {
      default: '#f0f0f0' // Fallback background color
    },
    
    
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundImage: `url(${bgimg})`, // Set your image path here
          
          backgroundAttachment: 'fixed',
          backgroundColor: '#EDE7E3', // Add your desired color as a fallback
        },
      },
    },
  }
});


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    

    <React.StrictMode>
      <Auth0Provider
          debug={true}
          domain={process.env.REACT_APP_AUTH0_DOMAIN}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={ window.location.origin }
          useRefreshTokens={true}
          cacheLocation="localstorage"
          authorizationParams={{
            audience: process.env.REACT_APP_AUTH0_AUDIENCE,
            redirect_uri: window.location.origin,
            scope: "openid profile email offline_access",
          }}
        >
          <ProtectedAppWrapper />
      </Auth0Provider>
    </React.StrictMode>
  </ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
